import { APICore } from './apiCore';

const api = new APICore();

// account
function locations(params: any) {
    const baseUrl = '/reservation/location/all';
    return api.create(`${baseUrl}`,{});
}

function getDetails(params: any) {
    const baseUrl = '/reservation/order/details';
    return api.create(`${baseUrl}`,params);
}

function makeOrder(params: any) {
    const baseUrl = '/reservation/order';
    return api.create(`${baseUrl}`,params);
}

function getOrders() {
    const baseUrl = '/reservation/order';
    return api.getEntity(`${baseUrl}`);
}

function getItem(params: any) {
    const baseUrl = `/reservation/order/${params}`;
    return api.getEntity(`${baseUrl}`);
}

function cancelOrder(params: any) {
    const baseUrl = '/reservation/cancel';
    return api.create(`${baseUrl}`,params);
}

function changeRecipient(params: any) {
    const baseUrl = '/reservation/recipient';
    return api.create(`${baseUrl}`,params);
}

function extentionDetails(params: any) {
    const baseUrl = '/reservation/extention/details';
    return api.create(`${baseUrl}`,params);
}

function extention(params: any) {
    const baseUrl = '/reservation/extention';
    return api.create(`${baseUrl}`,params);
}


export { 
    locations, 
    getDetails, 
    makeOrder, 
    getOrders, 
    cancelOrder, 
    changeRecipient, 
    extentionDetails, 
    extention,
    getItem 
};
