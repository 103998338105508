// helpers
import { useState } from 'react';
import { verify as verifyApi } from '../../helpers';
import { APICore, setAuthorization } from '../../helpers/api/apiCore';

export default function useVerify() {
    const api = new APICore();

    const [user, setUser] = useState();
    const [error, setError] = useState();

    const verify = ({ code}: { code: string }) => {
        const response = verifyApi({ code });
        response
            .then((response) => {
                setUser(response.data);
                api.setLoggedInUser(response.data);
                setAuthorization(response.data!['token']);
            })
            .catch((e) => {
                setError(e);
            });
    };

    return [user, error, verify];
}
