import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';

// components
import Root from './Root';
import PrivateRoute from './PrivateRoute';


// lazy load all the views
// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const SignUp = React.lazy(() => import('../pages/auth/SignUp'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const ResetPassword = React.lazy(() => import('../pages/auth/ResetPassword'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Verify = React.lazy(() => import('../pages/auth/Verify'));


// landings
const Chist = React.lazy(() => import('../pages/landings/Chist'));

// pages
const LockerSettings = React.lazy(() => import('../pages/other/account/LockerSettings'));
const OpeningHours = React.lazy(() => import('../pages/other/account/LockerSettings/openingHours'));
const OrderItems = React.lazy(() => import('../pages/other/account/LockerSettings/OrderItems'));
const OrderDetails = React.lazy(() => import('../pages/other/account/LockerSettings/OrderDetails'));
const OrdersList = React.lazy(() => import('../pages/other/account/OrdersList'));
const EditOrder = React.lazy(() => import('../pages/other/account/EditOrder'));
const NewItems = React.lazy(() => import('../pages/other/account/NewItems'));


const CompanyChist = React.lazy(() => import('../pages/other/CompanyChist'));



const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    return useRoutes([
        {
            // root route
            path: '/',
            element: <Root />,
        },
        {
            // public routes
            path: '/',
            children: [
                {
                    path: 'home',
                    element: <LoadComponent component={Chist} />,
                },
                {
                    path: 'aboutus',
                    element: <LoadComponent component={CompanyChist} />,
                },
                {
                    path: 'auth',
                    children: [
                        { path: 'login', element: <LoadComponent component={Login} /> },
                        // { path: 'signup', element: <LoadComponent component={SignUp} /> },
                        { path: 'forget-password', element: <LoadComponent component={ForgetPassword} /> },
                        { path: 'reset-password/:code', element: <LoadComponent component={ResetPassword} /> },
                        { path: 'confirm', element: <LoadComponent component={Confirm} /> },
                        { path: 'logout', element: <LoadComponent component={Logout} /> },
                        { path: 'Verify', element: <LoadComponent component={Verify} /> },
                    ],
                },
                
            ],
        },
        {
            // protected routes
            path: '/',
            element: <PrivateRoute roles={'Admin'} />,
            children: [
                {
                    path: 'orders',
                    children: [
                        { 
                            path: 'make', 
                            element: <LoadComponent component={LockerSettings} />, 
                            children: [
                                { path: 'opening', element: <LoadComponent component={OpeningHours} /> },
                                { path: 'lockers', element: <LoadComponent component={OrderItems} /> },
                                { path: 'details', element: <LoadComponent component={OrderDetails} /> },
                            ]
                        },
                        { path: 'list', element: <LoadComponent component={OrdersList} /> },
                        { path: 'edit/:i', element: <LoadComponent component={EditOrder} /> },
                        { path: 'items', element: <LoadComponent component={NewItems} /> },
                        
                    ],
                },
            ],
            
        },
        {
            // Not Found route
            path: '*',
            element: <Root />,
        },
    ]);
};

export default AllRoutes;
