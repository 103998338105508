// helpers
import { useState } from 'react';
import { resendVerify as resendVerifyApi } from '../../helpers';


export default function useResendVerify() : [any,any, () => void] {
    

    const [posRS, setPos] = useState("");
    const [negRS, setNeg] = useState("");

    const resendVerify = () => {
        setPos("")
        setNeg("")
        const response = resendVerifyApi();
        response
            .then((response) => {
                setPos(response.data);
            })
            .catch((e) => {
                setNeg(e);
            });
    };

    return [posRS, negRS, resendVerify];
}
