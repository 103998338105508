import { APICore } from './apiCore';

const api = new APICore();

// account
function login(params: { email: string; password: string }) {
    const baseUrl = '/login/';
    return api.create(`${baseUrl}`, params);
}

function verify(params: { code: string }) {
    const baseUrl = '/verify/';
    return api.create(`${baseUrl}`, params);
}

function resendVerify() {
    const baseUrl = '/verify/resend';
    return api.create(`${baseUrl}`, {});
}

function logout() {
    const baseUrl = '/logout/';
    return api.get(`${baseUrl}`, {});
}

function signup(params: { fullname: string; email: string; password: string }) {
    const baseUrl = '/register/';
    return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: { email: string }) {
    const baseUrl = '/forget-password/';
    return api.create(`${baseUrl}`, params);
}

function resetPassword(params: { password: string, confirmPassword: string, code: string }) {
    const baseUrl = '/reset-password/';
    return api.create(`${baseUrl}`, params);
}

export { login, logout, signup, forgotPassword, resetPassword, verify, resendVerify };
