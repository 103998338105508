import config from './config';
import { useEffect } from 'react';

import AOS from 'aos';

// routes
import Routes from './routes/Routes';

// helpers
//import { configureFakeBackend } from './helpers';

// Themes
//  For Default import Theme.scss
import './assets/scss/theme.scss';

// import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
const TawkMessengerReact = require('@tawk.to/tawk-messenger-react');

const App = () => {
    useEffect(() => {
        AOS.init();
    }, []);

    //configureFakeBackend();
    return ( 
        <div>

            <Routes />
            {/* <TawkMessengerReact
                propertyId={config.TAWK_PROPERTY_ID}
                widgetId={config.TAWK_WIDGET_ID}
            /> */}
        </div>
    );
};

export default App;
