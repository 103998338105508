import { useState } from 'react';

// helpers
import { resetPassword as resetPasswordApi } from '../../helpers';

export default function useResetPassword(): [string, string, boolean, ({ password, confirmPassword, code }: { password: string, confirmPassword: string, code: string }) => void] {
    const [resetPasswordSuccess, setResetPasswordSuccess] = useState('');
    const [error, setError] = useState('');
    const [passwordReset, setPasswordReset] = useState(false);

    const resetPassword = ({ password, confirmPassword, code }: { password: string, confirmPassword: string, code: string }) => {
        const response = resetPasswordApi({ password, confirmPassword, code });
        response
            .then((response) => {
                // console.log(response.data.message);
                setResetPasswordSuccess(response.data.message);
                setPasswordReset(true);
            })
            .catch((e) => {
                setError(e);
                setPasswordReset(false);
            });
    };

    return [resetPasswordSuccess, error, passwordReset, resetPassword];
}
